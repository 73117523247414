<template>
  <div id="CoreTechnology">
    <Header nav="2"></Header>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-else>
      <div class="banner">
        <img :src="bannerImg" />
      </div>

      <div class="technology">
        <div class="en"><span class="En">T</span><span>ECHNOLOGIES</span></div>
        <div class="ch">核心技术</div>
        <div class="pageTitle pageTitle1">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[0].title }}</div>
        </div>
        <div class="pageName pageName1">{{ pageList[0].name }}</div>
        <div class="pageText">{{ pageList[0].content }}</div>
        <img class="pageImg pageImg1" :src="pageList[0].image" />
      </div>

      <div class="pageContent2">
        <div class="pageTitle pageTitle2">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[1].title }}</div>
        </div>
        <div class="pageText pageText2">{{ pageList[1].content }}</div>
        <div class="page2">
          <div class="empty"></div>
          <div class="pageMain">
            <div class="pageItem">
              <img class="pageItemicon" src="../assets/icon04.png" />
              <div class="pageItemtitle">{{ pageList[2].title }}</div>
              <div class="pageItemcontent">{{ pageList[2].content }}</div>
            </div>
            <div class="pageItem">
              <img class="pageItemicon" src="../assets/icon05.png" />
              <div class="pageItemtitle">{{ pageList[3].title }}</div>
              <div class="pageItemcontent">{{ pageList[3].content }}</div>
            </div>
            <div class="pageItem">
              <img class="pageItemicon" src="../assets/icon06.png" />
              <div class="pageItemtitle">{{ pageList[4].title }}</div>
              <div class="pageItemcontent">{{ pageList[4].content }}</div>
            </div>
            <div class="pageItem">
              <img class="pageItemicon" src="../assets/icon07.png" />
              <div class="pageItemtitle">{{ pageList[5].title }}</div>
              <div class="pageItemcontent">{{ pageList[5].content }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="pageContent3">
        <div class="pageTitle pageTitle3">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[6].title }}</div>
        </div>
        <div class="pageText pageText3">{{ pageList[6].content }}</div>
        <div class="recommendPage">
          <swiper :options="option3" ref="mySwiper">
            <swiper-slide v-for="item in pageMsg" :key="item.id">
              <img class="pageItemicon" :src="item.image" />
              <div class="pageItemtitle">{{ item.title }}</div>
              <div class="pageItemcontent">
                {{ item.content }}
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>

      <div class="pageContent4">
        <div class="pageTitle pageTitle4">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[15].title }}</div>
        </div>
        <div class="pageText pageText4">{{ pageList[15].content }}</div>
        <img class="pageImg4" :src="pageList[15].image" />
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Icon, Loading, Swipe, SwipeItem } from "vant";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    Header,
    Footer,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      bannerImg: "",
      showloading: true,
      pageList: [],
      pageMsg: [],
      currents: 0,

      option3: {
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        slidesPerView: 1.49,
        centeredSlides: true,
        loop: true,
        touchRatio: 1, //触摸距离与slide滑动距离的比率。
        spaceBetween: 0,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // 核心技术-轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 6 })
      .then((res) => {
        // console.log(res.data.arr);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr[0].image;
          setTimeout(() => {
            this.showloading = false;
          }, 300);
        }
      });

    this.$axios
      .post("https://api1.pyua.net/index/content", { type: 6 })
      .then((res) => {
        // console.log(res.data.arr);
        if (res && res.code == 1) {
          this.pageList = res.data.arr;
          this.pageMsg = res.data.arr.slice(7, 15);
          // console.log(this.pageMsg);
        }
      });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
#CoreTechnology {
  box-sizing: border-box;
}
.banner {
  margin-top: 2.75rem;
  font-size: 0;
  img {
    width: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.en {
  font-family: "DIN";
  margin-top: 2.9375rem;
  margin-bottom: 0.3125rem;
  color: #d5e1db;
  font-size: 18px;
  line-height: 1;
  .En {
    font-size: 28px;
    line-height: 1;
  }
}
.ch {
  line-height: 1;
  color: #206c47;
  font-weight: 700;
  font-size: 18px;
}

.pageTitle {
  color: #206c47;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageName {
  color: #206c47;
  font-size: 14px;
}
.pageText {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  white-space: pre-wrap;
}
.pageImg {
  width: 100%;
}
.co-icon {
  width: 0.84375rem;
  height: 0.84375rem;
  margin-right: 0.5rem;
}

.technology {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f8f6;
  padding: 0 1rem;
  .pageTitle1 {
    margin-top: 1.875rem;
  }
  .pageName1 {
    margin: 1.5rem 0;
  }
  .pageImg1 {
    margin: 1.875rem 0 3.0625rem 0;
  }
}

.pageContent2 {
  padding: 0 1rem;
  .pageTitle2 {
    margin-top: 3.0625rem;
  }
  .pageText2 {
    margin: 1.5rem 0 1.875rem 0;
  }
  .page2 {
    position: relative;
    margin-bottom: 3.0625rem;
    .empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background-color: #278155;
      z-index: 2;
    }
  }
  .pageMain {
    background-image: linear-gradient(to bottom right, #2f9664, #206c47);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.875rem 1.125rem;
    position: relative;
    .pageItem {
      width: 50%;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid #fff;
        padding-bottom: 1.875rem;
      }
      &:nth-child(1),
      &:nth-child(3) {
        border-right: 1px solid #fff;
        padding-right: 1.125rem;
      }
      &:nth-child(2),
      &:nth-child(4) {
        padding-left: 1.125rem;
      }
      &:nth-child(3),
      &:nth-child(4) {
        padding-top: 1.875rem;
      }
      .pageItemicon {
        width: 1.625rem;
        height: 1.625rem;
      }
      .pageItemtitle {
        font-size: 1rem;
        margin: 1.5rem 0;
      }
      .pageItemcontent {
        text-align: justify;
        font-size: 0.875rem;
        height: 8.125rem;
        line-height: 1.5;
      }
    }
  }
}

// 八大优势
.pageContent3 {
  background-color: #f4f8f6;
  width: 100%;
  padding-bottom: 3.0625rem;
  .pageTitle3 {
    padding-top: 3.0625rem;
  }
  .pageText3 {
    margin: 1.5rem 0 1.875rem 0;
    padding: 0 1rem;
  }
  .recommendPage .swiper-container {
    position: relative;
    width: 100%;
    height: 20.6rem;
  }
  .recommendPage .swiper-container .swiper-slide {
    width: 100%;
    background-image: linear-gradient(to bottom right, #2f9664, #206c47);
    color: #fff;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pageItemicon {
      margin: 1.25rem 0.625rem;
    }
    .pageItemtitle {
      font-size: 1rem;
    }
    .pageItemcontent {
      font-size: 0.875rem;
      margin-top: 1.5rem;
      text-align: justify;
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    transition: 400ms;
    transform: scale(0.85);
  }

  /deep/ .swiper-pagination-bullet-active {
    opacity: 1;
    background: #fff;
  }
}

.pageContent4 {
  padding: 0 1rem;
  font-size: 0;
  .pageTitle4 {
    padding: 3.0625rem 0 1.5rem 0;
  }
  .pageImg4 {
    width: 100%;
    padding: 1.875rem 0 3.0625rem 0;
  }
}
</style>